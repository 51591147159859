import React, { useEffect, useState } from 'react';
import { graphql, useStaticQuery } from 'gatsby';
import {
    BannerHomePpal,
    GamesHome,
    ResultLotteries,
    CalculateYourPrize,
    DayPromotionHome
} from '@components';
import { Layout, SEO, Toast } from '@componentsShared';
import { getData, removeLocalStorage } from '@utils/transferData';
import Viewport from 'src/shared/hooks/viewport';
import { getMetadataPage } from '@utils/metadata';
import { ButtonsGames } from 'src/components/organisms/buttonsGames';

interface IProps {}

const Index = (props: IProps) => {
    const [toastState, setToastState] = useState({
        state: false,
        text: '',
        hasError: false
    });
    const viewport = Viewport();
    const [filter, setFilter] = useState();

    const dataHome = useStaticQuery(graphql`
        query QueryHome {
            allContentfulHome {
                edges {
                    node {
                        mainBanner {
                            desktopImage {
                                gatsbyImageData(height: 703, quality: 100, placeholder: BLURRED)
                                description
                            }
                            mobileImage {
                                gatsbyImageData(height: 413, quality: 100, placeholder: BLURRED)
                                description
                            }
                            informativeText {
                                raw
                            }
                            locationInformativeText
                            clock
                            clockDate
                            buttonText
                            url
                        }
                        secundaryBanner {
                            desktopImage {
                                description
                                file {
                                    url
                                }
                            }
                            adText {
                                raw
                            }
                            textLink
                            hyperlink
                            bannerType
                        }
                        promotionsBanner {
                            title
                            icon {
                                file {
                                    url
                                }
                            }
                            promotionText {
                                raw
                            }
                            secundaryText
                            gameImage {
                                fluid {
                                    src
                                }
                            }
                            clockDate
                        }
                        games {
                            id
                            image {
                                fluid {
                                    src
                                }
                                description
                            }
                            points
                            recomendationText
                            betFrom
                            win
                            state
                            url
                            gameName
                        }
                        metaData {
                            title
                            descripcion
                            keyWords
                            image {
                                file {
                                    url
                                }
                            }
                        }
                    }
                }
            }
        }
    `);

    const {
        allContentfulHome: {
            edges: [
                {
                    node: { mainBanner, games, promotionsBanner, secundaryBanner, metaData }
                }
            ]
        }
    } = dataHome;

    useEffect(() => {
        let isSuccessChangePassword = getData('SuccessChangePassword');
        console.log(isSuccessChangePassword);
        if (isSuccessChangePassword) {
            showToast('¡Lo lograste!, tu contraseña se ha cambiado.');
            removeLocalStorage('SuccessChangePassword');
        }

        let isMsgResendLinkActivation = getData('MsgResendLinkActivation');
        if (isMsgResendLinkActivation) {
            showToast(isMsgResendLinkActivation.msg, isMsgResendLinkActivation.hasError);
            removeLocalStorage('MsgResendLinkActivation');
        }
    }, []);

    const showToast = (text: string, hasError?: boolean) => {
        setToastState({ state: true, text: text, hasError: hasError || false });
        setTimeout(() => {
            setToastState({ state: false, text: '', hasError: hasError || false });
        }, 5000);
    };

    return (
        <Layout slug="/">
            <SEO {...getMetadataPage(metaData[0], '/')} />
            {toastState.state && (
                <Toast
                    text={toastState.text}
                    icon={toastState.hasError ? 'cross' : 'check'}
                    type={toastState.hasError ? 'danger' : 'info'}
                />
            )}

            <BannerHomePpal banner={mainBanner} />

            <ResultLotteries />

            {viewport.mobile && (
                        <ButtonsGames games={games} setFilter={setFilter} filter={filter} />
                    )}

            <GamesHome games={games} filter={filter} />

            <CalculateYourPrize dataBanner={secundaryBanner} />

            <DayPromotionHome promotionsBanner={promotionsBanner} />
        </Layout>
    );
};

export default Index;
