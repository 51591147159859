import { breakpoint } from "src/shared/styles/variables";
import styled from "styled-components";

export const Game = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    border-radius: 16px;
    color:${(props) => props.theme.colors.principal.greenBase};
    border: 1px solid ${(props) => props.theme.colors.principal.greenBase};
    box-sizing: border-box;
    border-radius: 16px;
    box-sizing: border-box;
    width: 91px;
    min-width: 91px;
    user-select: none;
    font-size: 10px;
    line-height: 12px;
    font-family: ${(props) => props.theme.fonts.lf_bold};
    height: 22px;
    max-width:91px;
    &.gameActive {
        background: ${(props) => props.theme.colors.alerts.green};
        color: ${(props) => props.theme.colors.text.gray};
    }
    &.gameInactive{
    }
`;
export const ContentInputs = styled.div`
    display: flex;
    flex-direction:row;
    background: rgba(35, 29, 51, 0.9);
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
    gap:17px 23px;
    padding: 14px 0px;
    ${breakpoint('lg')`
        display:none;
    
    `}

`;