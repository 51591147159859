import React, { useEffect } from 'react';
import Viewport from 'src/shared/hooks/viewport';
import { IProps, TGame } from '../containerGames/types';
import { ContentInputs, Game} from './styled';

export const ButtonsGames = (props: IProps) => {
    const viewport = Viewport();
    const { games, setFilter, filter } = props;
    useEffect(() => {
        viewport.desktop &&
        setFilter(false);
    }, [viewport]);
    return (
        <ContentInputs>
            {games.map((game: TGame) => {
                const gameActive: boolean = (filter === game ? true : false)
                return (
                        <Game
                            key={game.id}
                            className={gameActive ? 'gameActive' : 'gameInactive'}
                            onClick={()=> {
                                setFilter(game);
                                gameActive  ?
                                setFilter(false)
                                :
                                    setFilter(game);
                            }}
                        >
                        {game.title || game.gameName}
                        </Game>
                );
            })}
        </ContentInputs>
    );
};
